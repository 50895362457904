import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { motion } from "framer-motion";

const LendAccount = ({ user }) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    server: "EUW"
  });
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError("");
    
    try {
      const response = await fetch("https://api.eloheaven.net/community/uploadaccount", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          accountOwnerId: user.id
        })
      });

      if (!response.ok) {
        throw new Error("Failed to submit account");
      }

      setSuccess(true);
      setFormData({ username: "", password: "", server: "EUW" });
    } catch (err) {
      setError(err.message || "Failed to lend account");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="relative min-h-screen text-light" 
         style={{
           backgroundImage: `url('/images/background.png')`,
           backgroundSize: "cover",
           backgroundPosition: "center"
         }}>
      <div className="absolute inset-0 bg-black/60 backdrop-blur-s"></div>
      
      <Navbar user={user} />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="relative max-w-2xl mx-auto p-8 pt-24"
      >
        <div className="bg-dark/90 backdrop-blur-md border border-light/10 rounded-lg p-8 shadow-lg">
          <h1 className="text-3xl font-semibold mb-6 text-primary">Lend Account</h1>
          
          {/* Disclaimer */}
          <p className="text-light mb-6 text-sm">
            <strong>Disclaimer:</strong>
            Please only submit email verified accounts.
            Do not upload restricted accounts (they should be able to play ranked).
          </p>
          
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-light mb-2">Username</label>
              <input
                type="text"
                required
                className="w-full bg-dark/70 border border-light/20 rounded-lg p-3 focus:outline-none focus:border-primary"
                value={formData.username}
                onChange={(e) => setFormData({ ...formData, username: e.target.value })}
              />
            </div>

            <div>
              <label className="block text-light mb-2">Password</label>
              <input
                type="password"
                required
                className="w-full bg-dark/70 border border-light/20 rounded-lg p-3 focus:outline-none focus:border-primary"
                value={formData.password}
                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              />
            </div>

            <div>
              <label className="block text-light mb-2">Server</label>
              <select
                className="w-full bg-dark/70 border border-light/20 rounded-lg p-3 focus:outline-none focus:border-primary"
                value={formData.server}
                onChange={(e) => setFormData({ ...formData, server: e.target.value })}
              >
                {["EUW", "NA", "EUNE", "BR", "LAN", "LAS", "OCE", "RU", "TR", "JP", "KR"].map((server) => (
                  <option key={server} value={server}>{server}</option>
                ))}
              </select>
            </div>

            {error && <p className="text-red-500">{error}</p>}
            {success && <p className="text-green-500">Account submitted successfully!</p>}

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              disabled={submitting}
              className="w-full bg-primary text-black py-3 rounded-lg hover:bg-primary/80 transition-all"
            >
              {submitting ? "Submitting..." : "Lend Account"}
            </motion.button>
          </form>
        </div>
      </motion.div>
    </div>
  );
};

export default LendAccount;