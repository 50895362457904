import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { motion } from "framer-motion";

const Users = ({ user }) => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const usersPerPage = 10;
  const navigate = useNavigate();

  /*if (typeof user.websiteSettings === "string") {
    user.websiteSettings = JSON.parse(user.websiteSettings);
  }*/

  useEffect(() => {
    fetch(`https://api.eloheaven.net/users?page=${currentPage}`, { credentials: 'include' })
      .then((res) => res.json())
      .then((data) => {
        setUsers(data.users);
        setTotalPages(Math.ceil(data.totalUsers / usersPerPage));
      })
      .catch((err) => console.error("Error fetching users:", err));
  }, [currentPage]);

  const roleNames = [
    "Unknown",
    "Owner",
    "Admin",
    "Moderator",
    "Customer",
    "Booster",
  ];

  const handleUserClick = (userId) => {
    navigate(`/profile/${userId}`);
  };

  return (
    <div
      className="relative min-h-screen text-light"
      style={{
        backgroundImage: `url('/images/background.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Dark overlay*/}
      <div className="absolute inset-0 bg-black/70 backdrop-blur-sm z-0"></div>

      <Navbar user={user} />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="relative max-w-6xl mx-auto p-8 pt-24"
      >
        <div className="bg-dark/90 backdrop-blur-md border border-light/10 rounded-lg p-8 shadow-lg z-10 relative">
          <h1 className="text-3xl font-semibold mb-6 text-primary">Users</h1>
          <div className="overflow-hidden">
            <table className="w-full text-left border-collapse">
              <thead>
                <tr className="border-b border-light/10">
                  <th className="p-3">Profile</th>
                  <th className="p-3">Username</th>
                  <th className="p-3">Account Type</th>
                  <th className="p-3">Registered</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <motion.tr
                    key={user.id}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.05 }}
                    whileHover={{
                      backgroundColor: "rgba(255, 255, 255, 0.05)",
                      transition: { duration: 0.1 },
                    }}
                    className="border-b border-light/10 cursor-pointer"
                    onClick={() => handleUserClick(user.id)}
                  >
                    <td className="p-3">
                      <img
                        src={
                          user.ProfilePicture || "/images/default-avatar.png"
                        }
                        alt="Profile"
                        className="h-12 w-12 rounded-full border-2 border-primary"
                      />
                    </td>
                    <td className="p-3">{user.username}</td>
                    <td className="p-3 text-primary">
                      {roleNames[user.accountType] || "Unknown"}
                    </td>
                    <td className="p-3">
                      {new Date(user.registerDate).toLocaleDateString()}
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="mt-6 flex justify-between">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              className="px-4 py-2 bg-primary text-black rounded-lg hover:bg-primary/80 transition-all"
              disabled={currentPage === 1}
            >
              Previous
            </motion.button>
            <span className="text-light/80">
              Page {currentPage} of {totalPages}
            </span>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              className="px-4 py-2 bg-primary text-black rounded-lg hover:bg-primary/80 transition-all"
              disabled={currentPage >= totalPages}
            >
              Next
            </motion.button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Users;