import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import LeagueAccounts from './pages/LeagueAccounts';
import CommunityAccounts from './pages/CommunityAccounts';
import LendAccount from './pages/LendAccount';
import Users from './pages/Users';
import Rules from './pages/Rules';
import Help from './pages/Help';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import LeagueAccount from './pages/LeagueAccount';
import CommunityAccount from './pages/CommunityAccount';
import Error from './pages/Error';
import './App.css';

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('https://api.eloheaven.net/api/check-auth', { withCredentials: true })
      .then((response) => {
        if (response.data.authenticated) {
          setAuthenticated(true);
          setUser(response.data.user);
        } else {
          setAuthenticated(false);
          setUser(null);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        setAuthenticated(false);
        setUser(null);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        {}
        <Route
          path="/"
          element={authenticated ? <Navigate to="/dashboard" /> : <Home />}
        />

        {}
        <Route
          path="/dashboard"
          element={authenticated ? <Dashboard user={user} /> : <Navigate to="/" />}
        />
        <Route
          path="/league-accounts"
          element={authenticated ? <LeagueAccounts user={user} /> : <Navigate to="/" />}
        />
        <Route
          path="/community-accounts"
          element={authenticated ? <CommunityAccounts user={user} /> : <Navigate to="/" />}
        />
        <Route
          path="/lend-account"
          element={authenticated ? <LendAccount user={user} /> : <Navigate to="/" />}
        />
        <Route
          path="/users"
          element={authenticated ? <Users user={user} /> : <Navigate to="/" />}
        />
        <Route
          path="/rules"
          element={authenticated ? <Rules user={user} /> : <Navigate to="/" />}
        />
        <Route
          path="/help"
          element={authenticated ? <Help user={user} /> : <Navigate to="/" />}
        />
        <Route
          path="/profile/:userId?"
          element={authenticated ? <Profile /> : <Navigate to="/" />}
        />
        <Route
          path="/leagueaccount/:id"
          element={authenticated ? <LeagueAccount user={user} /> : <Navigate to="/" />}
        />
        <Route
          path="/communityaccount/:id"
          element={authenticated ? <CommunityAccount user={user} /> : <Navigate to="/" />}
        />
        <Route
          path="/settings"
          element={authenticated ? <Settings user={user} /> : <Navigate to="/" />}
        />
        <Route
          path="/error"
          element={authenticated ? <Error user={user} /> : <Navigate to="/" />}
        />

        {}
        <Route
          path="/discord"
          element={<Navigate to="https://discord.gg/eloheaven" />}
        />
      </Routes>
    </Router>
  );
};

export default App;