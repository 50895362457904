import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";

const Settings = ({ user }) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    bio: "",
    gameMainRoles: [],
    gameMainChampions: [],
    websiteSettings: { zoomedOut: false, wallpaper: "" }
  });
  const [allChampions, setAllChampions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const roles = ["Top", "Jungle", "Mid", "Bot", "Support"];

  const championExceptions = {
    'Wukong': 'MonkeyKing',
    'Nunu & Willump': 'Nunu',
    'Renata Glasc': 'Renata',
    'Dr. Mundo': 'DrMundo',
    'Vel\'Koz': 'Velkoz',
    'Kai\'Sa': 'Kaisa',
    'Kha\'Zix': 'Khazix',
    'Cho\'Gath': 'Chogath',
    'LeBlanc': 'Leblanc',
    'Bel\'Veth': 'Belveth',
  };

  const formatChampionName = (name) => {
    return championExceptions[name] || name.replace(/[^a-zA-Z0-9]/g, '');
  };

  const reverseFormatName = (formattedName) => {
    const entry = Object.entries(championExceptions).find(([_, v]) => v === formattedName);
    return entry ? entry[0] : formattedName;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [settingsRes, champsRes] = await Promise.all([
          fetch("https://api.eloheaven.net/user-settings", { credentials: 'include' }),
          fetch("https://api.eloheaven.net/getallchampionnames")
        ]);

        if (!settingsRes.ok || !champsRes.ok) throw new Error("Failed to fetch data");
        
        const settingsData = await settingsRes.json();
        const champsData = await champsRes.json();

        setUserData({
          ...settingsData,
          gameMainRoles: JSON.parse(settingsData.gameMainRoles),
          gameMainChampions: JSON.parse(settingsData.gameMainChampions),
          websiteSettings: JSON.parse(settingsData.websiteSettings)
        });
        setAllChampions(champsData);
      } catch (err) {
        setError("Failed to load settings");
      }
    };

    fetchData();
  }, []);

  const filteredChampions = allChampions.filter(champ =>
    champ.toLowerCase().includes(searchQuery.toLowerCase())
  ).slice(0, 3);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (!/\.(jpg|jpeg|png|webp)$/i.test(userData.websiteSettings.wallpaper)) {
      return setError("Invalid image URL format");
    }

    try {
      const response = await fetch("https://api.eloheaven.net/user-settings", {
        method: "PUT",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          bio: userData.bio,
          gameMainRoles: userData.gameMainRoles,
          gameMainChampions: userData.gameMainChampions,
          websiteSettings: userData.websiteSettings
        })
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.error || "Update failed");
      
      setSuccess("Settings updated successfully!");
      setTimeout(() => setSuccess(""), 3000);
    } catch (err) {
      setError(err.message);
    }
  };

  const toggleRole = (role) => {
    setUserData(prev => ({
      ...prev,
      gameMainRoles: prev.gameMainRoles.includes(role)
        ? prev.gameMainRoles.filter(r => r !== role)
        : prev.gameMainRoles.length < 5
          ? [...prev.gameMainRoles, role]
          : prev.gameMainRoles
    }));
  };

  const toggleChampion = (champ) => {
    setUserData(prev => ({
      ...prev,
      gameMainChampions: prev.gameMainChampions.includes(champ)
        ? prev.gameMainChampions.filter(c => c !== champ)
        : prev.gameMainChampions.length < 3
          ? [...prev.gameMainChampions, champ]
          : prev.gameMainChampions
    }));
  };  

  return (
    <div className="min-h-screen text-light" style={{
      backgroundImage: `url('${userData.websiteSettings.wallpaper}')`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    }}>
      <div className="absolute inset-0 bg-black/60 backdrop-blur-s"></div>
      <Navbar user={user} />
      
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="relative max-w-5xl mx-auto p-8 pt-24"
      >
        <form onSubmit={handleUpdate} className="bg-dark/90 backdrop-blur-md border border-light/10 rounded-lg p-8 shadow-lg space-y-8">
          {/* Bio Section */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="space-y-4"
          >
            <h2 className="text-2xl font-semibold text-primary">Profile Settings</h2>
            <textarea
              value={userData.bio}
              onChange={(e) => setUserData(prev => ({...prev, bio: e.target.value}))}
              className="w-full bg-dark/70 border border-light/10 rounded-lg p-4 focus:ring-2 focus:ring-primary"
              placeholder="Enter your bio..."
              maxLength="500"
              rows="4"
            />
          </motion.div>

          {/* Roles Selection */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="space-y-4"
          >
            <h3 className="text-xl font-semibold">Main Roles (Max 5)</h3>
            <div className="grid grid-cols-5 gap-4">
              {roles.map(role => (
                <motion.button
                  key={role}
                  type="button"
                  onClick={() => toggleRole(role)}
                  whileHover={{ scale: 1.05 }}
                  className={`p-2 rounded-lg border-2 ${
                    userData.gameMainRoles.includes(role)
                      ? "border-primary bg-primary/20"
                      : "border-light/10 hover:border-light/30"
                  }`}
                >
                  <img 
                    src={`/images/roles/${role}.webp`} 
                    alt={role}
                    className="w-12 h-12 mx-auto"
                  />
                  <span className="text-sm mt-1 block">{role}</span>
                </motion.button>
              ))}
            </div>
          </motion.div>

          {/* Champions Selection */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="space-y-4"
          >
            <h3 className="text-xl font-semibold">Main Champions (Max 3)</h3>
            <input
              type="text"
              placeholder="Search champions..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full bg-dark/70 border border-light/10 rounded-lg p-2 mb-4"
            />
            <div className="grid grid-cols-3 grid-cols-8 gap-4">
              {filteredChampions.map(champ => (
                <motion.button
                  key={champ}
                  type="button"
                  onClick={() => toggleChampion(champ)}
                  whileHover={{ scale: 1.05 }}
                  className={`relative rounded-lg overflow-hidden border-2 ${
                    userData.gameMainChampions.includes(champ)
                      ? "border-primary ring-2 ring-primary"
                      : "border-light/10 hover:border-light/30"
                  }`}
                >
                  <img
                    src={`https://ddragon.leagueoflegends.com/cdn/15.2.1/img/champion/${formatChampionName(champ)}.png`}
                    alt={champ}
                    className="w-full h-auto"
                  />
                  {userData.gameMainChampions.includes(champ) && (
                    <div className="absolute inset-0 bg-primary/30 backdrop-blur-s" />
                  )}
                  <span className="absolute bottom-0 left-0 right-0 bg-black/70 p-1 text-sm truncate">
                    {champ}
                  </span>
                </motion.button>
              ))}
              {filteredChampions.length === 0 && (
                <div className="col-span-3 text-center text-light/60">
                  No champions found
                </div>
              )}
            </div>
          </motion.div>

          {/* Website Settings */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="space-y-4"
          >
            <h3 className="text-xl font-semibold">Appearance</h3>
            <div className="flex items-center space-x-4">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={userData.websiteSettings.zoomedOut}
                  onChange={(e) => setUserData(prev => ({
                    ...prev,
                    websiteSettings: {
                      ...prev.websiteSettings,
                      zoomedOut: e.target.checked
                    }
                  }))}
                  className="w-5 h-5 text-primary bg-dark/70 border-light/10 rounded focus:ring-primary"
                />
                <span>Zoomed Out Mode</span>
              </label>
            </div>
            <div className="space-y-2">
              <label className="block">Custom Background URL</label>
              <input
                type="text"
                value={userData.websiteSettings.wallpaper}
                onChange={(e) => setUserData(prev => ({
                  ...prev,
                  websiteSettings: {
                    ...prev.websiteSettings,
                    wallpaper: e.target.value
                  }
                }))}
                className="w-full bg-dark/70 border border-light/10 rounded-lg p-2"
                placeholder="Enter image URL (jpg, png, webp)"
              />
            </div>
          </motion.div>

          {/* Status Messages */}
          {error && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="p-4 bg-red-500/20 border border-red-500 rounded-lg"
            >
              {error}
            </motion.div>
          )}
          {success && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="p-4 bg-green-500/20 border border-green-500 rounded-lg"
            >
              {success}
            </motion.div>
          )}

          <motion.button
            whileHover={{ scale: 1.05 }}
            type="submit"
            className="w-full py-3 bg-primary/80 hover:bg-primary rounded-lg font-semibold transition-all"
          >
            Save Changes
          </motion.button>
        </form>
      </motion.div>
    </div>
  );
};

export default Settings;