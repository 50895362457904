import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";

const Profile = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [allChampions, setAllChampions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await fetch("https://api.eloheaven.net/profile", { credentials: 'include' });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (data.authenticated) {
          setCurrentUser(data.user);
        } else {
          window.location.href = "/";
        }
      } catch (err) {
        console.error("Error fetching current user:", err);
        setError("Failed to load current user. Please try again later.");
      }
    };

    fetchCurrentUser();
  }, []);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const url = userId
          ? `https://api.eloheaven.net/profile/${userId}`
          : "https://api.eloheaven.net/profile";

        const response = await fetch(url, { credentials: 'include' });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (data.authenticated) {
          const user = {
            ...data.user,
            gameMainRoles: JSON.parse(data.user.gameMainRoles),
            gameMainChampions: JSON.parse(data.user.gameMainChampions),
          };
          setUser(user);
        } else {
          window.location.href = "/";
        }
      } catch (err) {
        console.error("Error fetching profile:", err);
        setError("Failed to load profile. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [userId]);

  const championExceptions = {
    'Wukong': 'MonkeyKing',
    'Nunu & Willump': 'Nunu',
    'Renata Glasc': 'Renata',
    'Dr. Mundo': 'DrMundo',
    'Vel\'Koz': 'Velkoz',
    'Kai\'Sa': 'Kaisa',
    'Kha\'Zix': 'Khazix',
    'Cho\'Gath': 'Chogath',
    'LeBlanc': 'Leblanc',
    'Bel\'Veth': 'Belveth',
  };

  const formatChampionName = (name) => {
    if (championExceptions[name]) return championExceptions[name];
    
    return name
      .replace(/[^a-zA-Z0-9]/g, '')
      .replace(/ /g, '')
      .replace(/'/g, '')
      .replace(/&/g, '');
  };

  useEffect(() => {
    const fetchChampions = async () => {
      try {
        const response = await fetch("https://api.eloheaven.net/getallchampionnames");
        const data = await response.json();
        setAllChampions(data);
      } catch (error) {
        console.error("Error fetching champions:", error);
      }
    };
    fetchChampions();
  }, []);

  if (loading) {
    return <div className="text-center text-light p-10">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-light p-10">{error}</div>;
  }

  if (!user) {
    return <div className="text-center text-light p-10">User not found.</div>;
  }

  const roleNames = ["Unknown", "Owner", "Admin", "Moderator", "Customer", "Booster"];
  const accountType = roleNames[user.accountType] || "Unknown";

  return (
    <div
      className="min-h-screen text-light"
      style={{
        backgroundImage: `url('/images/background.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Dark overlay */}
      {/*<div className="absolute inset-0 bg-black/70 backdrop-blur-sm"></div>*/}
      <div className="absolute inset-0 bg-black/60 backdrop-blur-s"></div>
      <Navbar user={currentUser} /> {/* Pass the current user to Navbar */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="relative max-w-5xl mx-auto p-8 pt-24"
      >
        <div className="bg-dark/90 backdrop-blur-md border border-light/10 rounded-lg p-8 shadow-lg">
          {/* Profile Picture & Info */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="flex items-center space-x-6"
          >
            <img
              src={user.ProfilePicture || "/images/default-avatar.png"}
              alt="Profile"
              className="h-24 w-24 rounded-full border-4 border-primary"
            />
            <div>
              <h1 className="text-3xl font-semibold">{user.username}</h1>
              <p className="text-primary text-lg">{accountType}</p>
              <p className="text-light/80">Joined: {new Date(user.registerDate).toLocaleDateString()}</p>
            </div>
          </motion.div>

          {/* Bio Section */}
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            className="mt-4 text-light/80 text-lg italic"
          >
            "{user.Bio || "No bio set"}"
          </motion.p>

          {/* Trust Rate Bar */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.8 }}
            className="mt-6"
          >
            <h2 className="text-xl font-semibold mb-2">Trust Rate</h2>
            <div className="w-full bg-light/20 rounded-full h-4">
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: `${user.trustRate}%` }}
                transition={{ duration: 1, delay: 1 }}
                className="bg-primary h-4 rounded-full"
              ></motion.div>
            </div>
            <p className="text-light/80 mt-1">{user.trustRate}%</p>
          </motion.div>

          {/* Roles & Champions Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 1 }}
            className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6"
          >
            {/* Role Icons */}
            <div>
              <h2 className="text-xl font-semibold mb-2">Main Roles</h2>
              <div className="flex space-x-3">
                {Array.isArray(user.gameMainRoles) && user.gameMainRoles.length > 0 ? (
                  user.gameMainRoles.map((role, index) => (
                    <motion.img
                      key={index}
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.5, delay: 1.2 + index * 0.1 }}
                      src={`/images/roles/${role}.webp`}
                      alt={role}
                      className="h-12 w-12"
                    />
                  ))
                ) : (
                  <p className="text-light/60">No roles selected</p>
                )}
              </div>
            </div>

            {/* Champion Icons */}
            <div>
              <h2 className="text-xl font-semibold mb-2">Main Champions</h2>
              <div className="flex space-x-3">
                {Array.isArray(user.gameMainChampions) && user.gameMainChampions.length > 0 ? (
                  user.gameMainChampions.map((champion, index) => (
                    <motion.img
                      key={champion}
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.5, delay: 1.4 + index * 0.1 }}
                      src={`https://ddragon.leagueoflegends.com/cdn/15.2.1/img/champion/${formatChampionName(champion)}.png`}
                      alt={champion}
                      className="h-12 w-12 rounded-lg border-2 border-light"
                    />
                  ))
                ) : (
                  <p className="text-light/60">No champions selected</p>
                )}
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default Profile;