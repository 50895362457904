import React from 'react';
import Navbar from '../components/Navbar';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Error = ({ user }) => {
  return (
    <div
      className="min-h-screen text-light"
      style={{
        backgroundImage: `url('/images/background.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Dark overlay */}
      <div className="absolute inset-0 bg-black/60 backdrop-blur-s"></div>
      <Navbar user={user} />
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="relative max-w-7xl mx-auto p-8 pt-24"
      >
        <div className="flex flex-col items-center justify-center min-h-[60vh] text-center">
          <div className="bg-red-500/20 p-8 rounded-2xl border border-red-500/50 backdrop-blur-lg">
            <h1 className="text-4xl font-bold mb-4 flex items-center justify-center gap-3">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
              Access Denied
            </h1>
            <p className="text-xl text-light/80 mb-8">
              You do not have the required permissions to view this page.
            </p>
            <Link 
              to="/dashboard"
              className="bg-primary/20 hover:bg-primary/30 border border-primary/50 px-6 py-3 rounded-lg transition-all"
            >
              Return to Dashboard
            </Link>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Error;