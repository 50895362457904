import React from 'react';
import Navbar from '../components/Navbar';
import { motion } from 'framer-motion';

const Dashboard = ({ user }) => {
  return (
    <div
      className="min-h-screen text-light"
      style={{
        backgroundImage: `url('/images/background.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Dark overlay */}
      <div className="absolute inset-0 bg-black/60 backdrop-blur-s"></div>
      <Navbar user={user} />
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="relative max-w-7xl mx-auto p-8 pt-24"
      >
        {}
      </motion.div>
    </div>
  );
};

export default Dashboard;