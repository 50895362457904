import React from 'react';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar';
import { FaDiscord, FaUserShield, FaLockOpen, FaMedal, FaTools, FaHandshake } from 'react-icons/fa';

const Help = ({ user }) => {
  const helpItems = [
    {
      icon: <FaDiscord className="text-3xl text-purple-400" />,
      title: "Account In Use?",
      content: "Politely ask in Discord if the user can release it. Never demand - we're a friendly community!"
    },
    {
      icon: <FaUserShield className="text-3xl text-blue-400" />,
      title: "Need Special Access",
      content: "If an account requires higher Trust Rate, message admins with your request and reasons"
    },
    {
      icon: <FaMedal className="text-3xl text-yellow-400" />,
      title: "Build Trust Rate",
      content: "Gain Trust by: Following rules • Keeping good win rate • Being kind • Returning accounts promptly"
    },
    {
      icon: <FaLockOpen className="text-3xl text-green-400" />,
      title: "Account Issues",
      content: "Credentials not working? Don't panic! Wait 10 mins then contact admins if still broken"
    },
    {
      icon: <FaTools className="text-3xl text-red-400" />,
      title: "Website Errors",
      content: "Found a bug? Send screenshot and steps to reproduce it to admins on Discord"
    },
    {
      icon: <FaHandshake className="text-3xl text-pink-400" />,
      title: "Good Behavior Tips",
      content: "Always click 'Done' after use • Report problems • Help newcomers • Respect everyone"
    }
  ];

  return (
    <div className="min-h-screen bg-dark text-light">
      <Navbar user={user} />
      
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="max-w-6xl mx-auto px-4 pt-24 pb-12 sm:px-6 lg:px-8"
      >
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4 text-white">
            Elo Heaven Support
          </h1>
          <p className="text-gray-300 text-lg">
            Quick help for common questions and issues
          </p>
        </div>

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {helpItems.map((item, index) => (
            <motion.div
              key={index}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: index * 0.1 }}
              className="bg-dark-700 p-6 rounded-lg border border-gray-700 hover:border-purple-400 transition-all"
            >
              <div className="mb-3">{item.icon}</div>
              <h3 className="text-xl font-semibold mb-3 text-white">
                {item.title}
              </h3>
              <p className="text-gray-300 text-sm leading-relaxed">
                {item.content}
              </p>
            </motion.div>
          ))}
        </div>

        <div className="mt-12 text-center border-t border-gray-700 pt-8">
          <p className="text-gray-400 text-sm">
            Need more help? Join our Discord server<br />
            Average response time: 15-30 minutes
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default Help;