import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { motion } from "framer-motion";
import "./Home.css";

const LeagueAccounts = ({ user }) => {
  const [accounts, setAccounts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    server: "",
    rank: "",
    champion: "",
    be: "",
    beingUsed: false,
  });
  const [championSuggestions, setChampionSuggestions] = useState([]);
  const [allChampions, setAllChampions] = useState([]);
  const accountsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    fetch("https://api.eloheaven.net/getallchampionnames")
      .then((res) => res.json())
      .then((data) => setAllChampions(data))
      .catch((err) => console.error("Error fetching champion names:", err));
  }, []);

  useEffect(() => {
    const filterQuery = Object.entries(filters)
      .map(([key, value]) => {
        if (value) {
          if (key === "beingUsed") {
            return `${key}=${value}`;
          } else {
            return `${key}=${value}`;
          }
        }
        return "";
      })
      .filter((item) => item)
      .join("&");

      fetch(`https://api.eloheaven.net/leagueaccounts?page=${currentPage}&${filterQuery}`, { 
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then((res) => res.json())
      .then((data) => {
        setAccounts(data.accounts);
        setTotalPages(Math.ceil(data.totalAccounts / accountsPerPage));
      })
      .catch((err) => console.error("Error fetching league accounts:", err));
  }, [currentPage, filters]);

  const handleChampionInputChange = (e) => {
    const value = e.target.value;
    setCurrentPage(1);
    setFilters((prev) => ({ ...prev, champion: value }));
  
    if (value.length > 1) {
      const suggestions = allChampions.filter((champion) =>
        champion.toLowerCase().includes(value.toLowerCase())
      );
      setChampionSuggestions(suggestions);
    } else {
      setChampionSuggestions([]);
    }
  };

  const championExceptions = {
    'Wukong': 'MonkeyKing',
    'Nunu & Willump': 'Nunu',
    'Renata Glasc': 'Renata',
    'Dr. Mundo': 'DrMundo',
    'Vel\'Koz': 'Velkoz',
    'Kai\'Sa': 'Kaisa',
    'Kha\'Zix': 'Khazix',
    'Cho\'Gath': 'Chogath',
    'LeBlanc': 'Leblanc',
    'Bel\'Veth': 'Belveth',
  };

  const formatChampionName = (name) => {
    if (championExceptions[name]) return championExceptions[name];
    
    return name
      .replace(/[^a-zA-Z0-9]/g, '')
      .replace(/ /g, '')
      .replace(/'/g, '')
      .replace(/&/g, '');
  };

  const handleAccountClick = (accountId, beingUsed) => {
    /*if (beingUsed) {
      return;
    }*/
    navigate(`/leagueaccount/${accountId}`);
  };

  const capitalizeServer = (server) => {
    return server.toUpperCase();
  };
  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCurrentPage(1);
    setFilters((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <div
      className="relative min-h-screen text-light"
      style={{
        backgroundImage: `url('/images/background.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Dark overlay */}
      {/*<div className="absolute inset-0 bg-black/70 backdrop-blur-sm"></div>*/}
      <div className="absolute inset-0 bg-black/60 backdrop-blur-s"></div>

      <Navbar user={user} />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="relative max-w-6xl mx-auto p-8 pt-24"
      >
        <div className="bg-dark/90 backdrop-blur-md border border-light/10 rounded-lg p-8 shadow-lg z-10 relative">
          <h1 className="text-3xl font-semibold mb-6 text-primary">
            League Accounts
          </h1>

          {/* Filters Section */}
          <div className="mb-6 flex flex-wrap gap-4 items-center">
            {/* Server Filter */}
            <motion.div
              className="flex-1 min-w-[150px]"
            >
              <select
                name="server"
                value={filters.server}
                onChange={handleFilterChange}
                className="w-full bg-dark text-light border border-light/20 rounded-md p-2 focus:outline-none focus:border-primary"
              >
                <option value="">Select Server</option>
                {[
                  "EUW",
                  "EUNE",
                  "NA",
                  "BR",
                  "LAN",
                  "LAS",
                  "ME",
                  "OCE",
                  "TR",
                  "RU",
                ].map((server) => (
                  <option key={server} value={server}>
                    {server}
                  </option>
                ))}
              </select>
            </motion.div>

            {/* Rank Filter */}
            <motion.div
              className="flex-1 min-w-[150px]"
            >
              <select
                name="rank"
                value={filters.rank}
                onChange={handleFilterChange}
                className="w-full bg-dark text-light border border-light/20 rounded-md p-2 focus:outline-none focus:border-primary"
              >
                <option value="">Select Rank</option>
                {[
                  "Iron",
                  "Bronze",
                  "Silver",
                  "Gold",
                  "Platinum",
                  "Emerald",
                  "Diamond",
                  "Master",
                  "Grandmaster",
                  "Challenger",
                ].map((rank) => (
                  <option key={rank} value={rank}>
                    {rank}
                  </option>
                ))}
              </select>
            </motion.div>

            {/* Champion Filter */}
            <motion.div className="flex-1 min-w-[150px]">
              <div className="relative">
                <input
                  type="text"
                  name="champion"
                  value={filters.champion}
                  onChange={handleChampionInputChange}
                  placeholder="Search Champion"
                  className="w-full bg-dark text-light border border-light/20 rounded-md p-2 focus:outline-none focus:border-primary"
                />
                {championSuggestions.length > 0 && (
                  <div className="absolute top-12 left-0 bg-dark/90 border border-light/20 rounded-md w-full z-20">
                    {championSuggestions.map((champion) => (
                      <div
                        key={champion}
                        className="flex items-center p-2 hover:bg-light/10 cursor-pointer"
                        onClick={() => {
                          setCurrentPage(1);
                          setFilters((prev) => ({ ...prev, champion }));
                          setChampionSuggestions([]);
                        }}
                      >
                        <img
                          src={`https://ddragon.leagueoflegends.com/cdn/15.2.1/img/champion/${formatChampionName(champion)}.png`}
                          alt={champion}
                          className="h-8 w-8 rounded-full mr-2"
                        />
                        <span className="text-light">{champion}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </motion.div>

            {/* BE Filter */}
            <motion.div className="flex-1 min-w-[150px]">
              <select
                name="be"
                value={filters.be}
                onChange={handleFilterChange}
                className="w-full bg-dark text-light border border-light/20 rounded-md p-2 focus:outline-none focus:border-primary"
              >
                <option value="">Select BE</option>
                {["450", "1350", "3150", "4800", "6300"].map((be) => (
                  <option key={be} value={be}>
                    {be}+
                  </option>
                ))}
              </select>
            </motion.div>

            {/* Being Used Checkbox */}
            <motion.div className="flex items-center space-x-2">
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="checkbox"
                  name="beingUsed"
                  checked={filters.beingUsed}
                  onChange={handleFilterChange}
                  className="form-checkbox h-5 w-5 text-primary bg-dark border-light/20 rounded focus:ring-primary"
                />
                <span className="text-light">Being Used</span>
              </label>
            </motion.div>
          </div>

          {/* Table Section */}
          <div className="overflow-hidden">
            <table className="w-full text-left border-collapse">
              <thead>
                <tr className="border-b border-light/10">
                  <th className="p-3">Profile</th>
                  <th className="p-3">Riot ID</th>
                  <th className="p-3">Server</th>
                  <th className="p-3">SoloQueue Rank</th>
                  <th className="p-3">FlexQueue Rank</th>
                  <th className="p-3">BE</th>
                  <th className="p-3">Champions</th>
                </tr>
              </thead>
              <tbody>
                {accounts.map((account, index) => (
                  <motion.tr
                    key={account.id}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.05 }}
                    whileHover={{
                      backgroundColor: "rgba(255, 255, 255, 0.05)",
                      transition: { duration: 0.1 },
                    }}
                    className={`relative border-b border-light/10 cursor-pointer ${
                      account.beingUsed ? "opacity-50" : ""
                    }`}
                    onClick={() =>
                      handleAccountClick(account.id, account.beingUsed)
                    }
                  >
                    <td className="p-3">
                      <div className="relative">
                        <img
                          src={`https://raw.communitydragon.org/latest/plugins/rcp-be-lol-game-data/global/default/v1/profile-icons/${account.profileIconId}.jpg`}
                          alt="Profile Icon"
                          className="h-12 w-12 rounded-full border-2 border-primary"
                        />
                        <span className="absolute bottom-0 right-3 bg-dark/80 text-light text-xs px-1 rounded-full border border-primary">
                          {account.level}
                        </span>
                      </div>
                    </td>
                    <td className="p-3 text-primary">
                      <a
                        href={`https://www.leagueofgraphs.com/summoner/${
                          account.server
                        }/${account.RiotID.replace("#", "-")}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="no-underline hover:text-primary/80"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {account.RiotID}
                      </a>
                    </td>
                    <td className="p-3">{capitalizeServer(account.server)}</td>
                    <td className="p-3">
                      <div className="flex items-center space-x-2">
                        <img
                          src={`/images/ranks/${account.SoloQueueRank.split(
                            " "
                          )[0].toLowerCase()}.png`}
                          alt="Solo Queue Rank"
                          className="h-8 w-8"
                        />
                        <div>
                          <span className="text-light">
                            {account.SoloQueueRank}
                          </span>
                          <span className="text-light/60 block text-sm">
                            {account.SoloQueueVictories}W /{" "}
                            {account.SoloQueueLosses}L
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="p-3">
                      <div className="flex items-center space-x-2">
                        <img
                          src={`/images/ranks/${account.FlexQueueRank.split(
                            " "
                          )[0].toLowerCase()}.png`}
                          alt="Flex Queue Rank"
                          className="h-8 w-8"
                        />
                        <div>
                          <span className="text-light">
                            {account.FlexQueueRank}
                          </span>
                          <span className="text-light/60 block text-sm">
                            {account.FlexQueueVictories}W /{" "}
                            {account.FlexQueueLosses}L
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="p-3">
                      <div className="flex items-center space-x-2">
                        <img
                          src="/images/BE.webp"
                          alt="BE"
                          className="h-8 w-8"
                        />
                        <span className="text-light">{account.be}</span>
                      </div>
                    </td>
                    <td className="p-3">
                      <div className="flex items-center space-x-2">
                        <img
                          src="/images/champion.png"
                          alt="Champions"
                          className="h-8 w-8"
                        />
                        <span className="text-light">
                          {account.champions
                            ? JSON.parse(account.champions).length
                            : 0}
                        </span>
                      </div>
                    </td>
                    {account.beingUsed == true && (
                      <div className="absolute inset-0 flex items-center justify-center z-10 pointer-events-none">
                        <div className="absolute inset-0 bg-black/50 backdrop-blur-sm"></div>
                        <div className="relative flex items-center space-x-2 text-light font-semibold text-lg">
                          <span>🔒</span>
                          <span>Account being used by Booster</span>
                        </div>
                      </div>
                    )}
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="mt-6 flex justify-between">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              className="px-4 py-2 bg-primary text-black rounded-lg hover:bg-primary/80 transition-all"
              disabled={currentPage === 1}
            >
              Previous
            </motion.button>
            <span className="text-light/80">
              Page {currentPage} of {totalPages}
            </span>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              className="px-4 py-2 bg-primary text-black rounded-lg hover:bg-primary/80 transition-all"
              disabled={currentPage >= totalPages}
            >
              Next
            </motion.button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default LeagueAccounts;