import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaDiscord } from 'react-icons/fa';
import axios from 'axios';
import './Home.css';

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://api.eloheaven.net/api/check-auth', { withCredentials: true })
      .then((response) => {
        if (response.data.authenticated) {
          navigate('/dashboard');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
      });
  }, [navigate]);

  return (
    <div className="home-container">
      <div className="card">
        <div className="header">
          <h1 className="title">
            <span className="gradient-text">EloHeaven</span>
          </h1>
          <img src="/images/logo_white.png" alt="EloHeaven Logo" className="logo" />
        </div>
        <p className="subtitle">Manage your League of Legends accounts with ease.</p>
        <a href="https://api.eloheaven.net/auth/discord" className="login-button">
          <FaDiscord className="icon" />
          Login with Discord
        </a>
      </div>
    </div>
  );
};

export default Home;