import React from 'react';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar';
import { FaShieldAlt, FaUserLock, FaCoins, FaHandHolding, FaUsers, FaBan } from 'react-icons/fa';

const Rules = ({ user }) => {
  const rules = [
    {
      icon: <FaShieldAlt className="text-3xl text-blue-400" />,
      title: "Follow Riot's Rules",
      content: "No cheating, scripting, or toxic behavior. Never AFK in games. Treat others with respect."
    },
    {
      icon: <FaHandHolding className="text-3xl text-green-400" />,
      title: "Share Accounts Fairly",
      content: "Click 'Done' when finished. Don't keep accounts more than 1 hour if not playing. Let others use them too."
    },
    {
      icon: <FaCoins className="text-3xl text-yellow-400" />,
      title: "Account Currency",
      content: "Never spend RP or skin shards. You can use BE to buy champions, and consume champion shards."
    },
    {
      icon: <FaUserLock className="text-3xl text-red-400" />,
      title: "Account Security",
      content: "Never save login details or share them with friends. Credentials will change automatically for safety."
    },
    {
      icon: <FaUsers className="text-3xl text-purple-400" />,
      title: "Friends & Chat",
      content: "You can add friends but don't remove existing friends. Never chat with the account's friends."
    },
    {
      icon: <FaBan className="text-3xl text-pink-400" />,
      title: "High Rank Accounts",
      content: "For accounts above Emerald 2: Play seriously. Try your best to win every game."
    }
  ];

  return (
    <div className="min-h-screen bg-dark text-light">
      <Navbar user={user} />
      
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="max-w-6xl mx-auto px-4 pt-24 pb-12 sm:px-6 lg:px-8"
      >
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4 text-white">
            Elo Heaven Rules
          </h1>
          <p className="text-gray-300 text-lg">
            Keep our community safe and fun for everyone
          </p>
        </div>

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {rules.map((rule, index) => (
            <motion.div
              key={index}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: index * 0.1 }}
              className="bg-dark-700 p-6 rounded-lg border border-gray-700 hover:border-blue-400 transition-all"
            >
              <div className="mb-3">{rule.icon}</div>
              <h3 className="text-xl font-semibold mb-3 text-white">
                {rule.title}
              </h3>
              <p className="text-gray-300 text-sm leading-relaxed">
                {rule.content}
              </p>
            </motion.div>
          ))}
        </div>

        <div className="mt-12 text-center border-t border-gray-700 pt-8">
          <p className="text-gray-400 text-sm">
            Breaking rules may lead to a permanent ban<br />
            Contact admins on Discord if you have any questions
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default Rules;