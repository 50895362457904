import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";
import {
  FaGamepad,
  FaLock,
  FaCopy,
  FaSearch,
  FaTimes,
  FaUserShield,
  FaCheck,
} from "react-icons/fa";
import Navbar from "../components/Navbar";

const LeagueAccount = ({ user }) => {
  const { id } = useParams();
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showChampionsModal, setShowChampionsModal] = useState(false);
  const [showCredentialsModal, setShowCredentialsModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [credentials, setCredentials] = useState(null);
  const [copiedField, setCopiedField] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(null);
  const [isUsingAccount, setIsUsingAccount] = useState(false);

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const response = await axios.get(`https://api.eloheaven.net/leagueaccounts/${id}`, { withCredentials: true });
        setAccount(response.data);
      } catch (err) {
        setError("Failed to fetch account details");
      } finally {
        setLoading(false);
      }
    };
    fetchAccount();
  }, [id]);

  const handleKeyPress = useCallback((e) => {
    if (e.key === "Escape") {
      setShowChampionsModal(false);
      setShowCredentialsModal(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress]);

  const handleUseAccount = async () => {
    try {
      if (isUsingAccount) {
        if (credentials) {
          setShowCredentialsModal(true);
        } else {
          const response = await axios.get(`https://api.eloheaven.net/getAccountCredentials/${id}`, { withCredentials: true });
          setCredentials(response.data.credentials);
          setShowCredentialsModal(true);
        }
      } else {
        setShowConfirmation("use");
      }
    } catch (err) {
      alert(err.response?.data?.error || "Failed to access account");
    }
  };

  const confirmUseAccount = async () => {
    try {
      const response = await axios.post(`https://api.eloheaven.net/useAccount/${id}`, {}, { withCredentials: true });

      if (response.data.success) {
        setIsUsingAccount(true);
        setShowConfirmation(null);

        const credsResponse = await axios.get(`https://api.eloheaven.net/getAccountCredentials/${id}`, { withCredentials: true });
        setCredentials(credsResponse.data.credentials);
        setShowCredentialsModal(true);
      }
    } catch (err) {
      alert(err.response?.data?.error || "Failed to use account");
    }
  };

  const handleStopUsingAccount = async () => {
    try {
      await axios.post(`https://api.eloheaven.net/stopUsingAccount/${id}`, {}, { withCredentials: true });
      setShowCredentialsModal(false);
      setCredentials(null);
      setIsUsingAccount(false);
    } catch (err) {
      alert(err.response?.data?.error || "Failed to stop using account");
    }
  };

  useEffect(() => {
    const checkAccountStatus = async () => {
      try {
        const [usageResponse, accountResponse] = await Promise.all([
          axios.get(`https://api.eloheaven.net/checkAccountUsage/${id}`, { withCredentials: true }),
          axios.get(`https://api.eloheaven.net/leagueaccounts/${id}`, { withCredentials: true }),
        ]);

        setAccount(accountResponse.data);
        setIsUsingAccount(usageResponse.data.isUsing);

        if (usageResponse.data.isUsing) {
          const credsResponse = await axios.get(`https://api.eloheaven.net/getAccountCredentials/${id}`, { withCredentials: true });
          setCredentials(credsResponse.data.credentials);
          setShowCredentialsModal(true);
        }
      } catch (error) {
        setError("Failed to load account data");
      } finally {
        setLoading(false);
      }
    };

    checkAccountStatus();
  }, [id]);

  const copyToClipboard = (text, fieldName) => {
    navigator.clipboard.writeText(text);
    setCopiedField(fieldName);
    setTimeout(() => setCopiedField(null), 2000);
  };

  const championExceptions = {
    Wukong: "MonkeyKing",
    "Nunu & Willump": "Nunu",
    "Renata Glasc": "Renata",
    "Dr. Mundo": "DrMundo",
    "Vel'Koz": "Velkoz",
    "Kai'Sa": "Kaisa",
    "Kha'Zix": "Khazix",
    "Cho'Gath": "Chogath",
    LeBlanc: "Leblanc",
    "Bel'Veth": "Belveth",
  };

  const formatChampionName = (name) => {
    if (championExceptions[name]) return championExceptions[name];

    return name
      .replace(/[^a-zA-Z0-9]/g, "")
      .replace(/ /g, "")
      .replace(/'/g, "")
      .replace(/&/g, "");
  };

  const filteredChampions = account
    ? JSON.parse(account.champions).filter((champion) =>
        champion.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  if (loading || error)
    return (
      <div className="loading-screen">{loading ? "Loading..." : error}</div>
    );

  return (
    <div className="min-h-screen bg-dark text-light font-sans relative">
      {/* Background Layer */}
      <div className="absolute inset-0 bg-gradient-to-br from-dark/90 to-primary/10 z-0"></div>

      <Navbar user={user} />

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative max-w-7xl mx-auto p-8 pt-24"
      >
        {/* Main Card Container */}
        <div className="bg-dark/80 backdrop-blur-lg border-2 border-primary/20 rounded-xl p-8 shadow-2xl relative">
          {/* Top Bar - Currency & Refunds */}
          <div className="absolute right-6 top-6 flex gap-4 items-center">
            <div className="flex items-center gap-3 bg-dark/70 px-4 py-3 rounded-lg">
              <img src="/images/BE.webp" className="h-8 w-8" alt="BE" />
              <span className="text-xl">{account.be}</span>
            </div>
            <div className="flex items-center gap-3 bg-dark/70 px-4 py-3 rounded-lg">
              <img src="/images/RP.webp" className="h-8 w-8" alt="RP" />
              <span className="text-xl">{account.rp}</span>
            </div>
            <div className="flex items-center gap-3 bg-dark/70 px-4 py-3 rounded-lg">
              <img
                src="/images/refund.webp"
                className="h-8 w-8"
                alt="Refunds"
              />
              <span className="text-xl">{account.refunds}</span>
            </div>
          </div>

          {/* Profile Section with Adjusted Level */}
          <div className="flex items-start gap-6 mb-12">
            <div className="relative">
              <img
                src={`https://raw.communitydragon.org/latest/plugins/rcp-be-lol-game-data/global/default/v1/profile-icons/${account.profileIconId}.jpg`}
                className="h-24 w-24 rounded-full border-4 border-primary/50"
                alt="Profile"
              />
              <div className="absolute -bottom-1 -right-2 bg-dark/90 px-2 py-1 rounded-full border border-primary/50 text-sm -mt-2">
                Lv. {account.level}
              </div>
            </div>

            <div className="flex-1">
              <a
                href={`https://www.leagueofgraphs.com/summoner/${
                  account.server
                }/${account.RiotID.replace("#", "-")}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-3xl font-bold mb-2 text-primary hover:text-primary/80 transition-colors"
              >
                {account.RiotID}
              </a>
              <div className="flex flex-col gap-2">
                <div
                  className={`flex items-center gap-2 ${
                    account.accountInGame ? "text-green-400" : "text-red-400"
                  }`}
                >
                  <FaGamepad />
                  <span>{account.accountInGame ? "In Game" : "Offline"}</span>
                </div>
                <div className="flex items-center gap-2 bg-dark-800 px-4 py-2 rounded-lg border border-primary/20 w-fit">
                  <span className="text-light/80 text-sm">Trust Required:</span>
                  <div className="w-32 h-2 bg-dark-900 rounded-full overflow-hidden relative">
                    <div
                      className="h-full bg-gradient-to-r from-primary to-primary/70 transition-all duration-500"
                      style={{ width: `${Math.max(account.trustRateReq, 5)}%` }}
                    />
                  </div>
                  <span className="text-primary text-sm">
                    {account.trustRateReq}%
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Ranked Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
            <div className="bg-dark/70 p-4 rounded-xl border border-primary/20">
              <div className="flex items-center gap-4">
                <img
                  src={`/images/ranks/${account.SoloQueueRank.split(
                    " "
                  )[0].toLowerCase()}.webp`}
                  className="h-16 w-16"
                  alt="Solo Rank"
                />
                <div>
                  <h3 className="text-lg font-bold mb-1">Solo/Duo Queue</h3>
                  <p className="text-xl mb-1">{account.SoloQueueRank}</p>
                  <p className="text-light/80 text-sm">
                    {account.SoloQueueVictories}W / {account.SoloQueueLosses}L
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-dark/70 p-4 rounded-xl border border-primary/20">
              <div className="flex items-center gap-4">
                <img
                  src={`/images/ranks/${account.FlexQueueRank.split(
                    " "
                  )[0].toLowerCase()}.webp`}
                  className="h-16 w-16"
                  alt="Flex Rank"
                />
                <div>
                  <h3 className="text-lg font-bold mb-1">Flex Queue</h3>
                  <p className="text-xl mb-1">{account.FlexQueueRank}</p>
                  <p className="text-light/80 text-sm">
                    {account.FlexQueueVictories}W / {account.FlexQueueLosses}L
                  </p>
                </div>
              </div>
            </div>
          </div>

          <style jsx>{`
            .custom-scroll::-webkit-scrollbar {
              width: 8px;
              background: rgba(255, 255, 255, 0.05);
            }

            .custom-scroll::-webkit-scrollbar-thumb {
              background: rgba(255, 255, 255, 0.2);
              border-radius: 4px;
            }
          `}</style>

          {/* Champions Modal */}
          <AnimatePresence>
            {showChampionsModal && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center"
              >
                <motion.div
                  initial={{ y: 50 }}
                  animate={{ y: 120 }}
                  className="bg-dark/90 border border-primary/20 rounded-xl w-full max-w-4xl p-6 mx-4 relative"
                >
                  <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold">Champions</h2>
                    <button
                      onClick={() => setShowChampionsModal(false)}
                      className="text-light/60 hover:text-light"
                    >
                      <FaTimes size={24} />
                    </button>
                  </div>

                  <div className="relative mb-6">
                    <input
                      type="text"
                      placeholder="Search champions..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full bg-dark/70 p-3 pl-12 rounded-lg border border-primary/20 focus:border-primary"
                    />
                    <FaSearch className="absolute left-4 top-1/2 -translate-y-1/2 text-light/40" />
                  </div>

                  {/* Added custom scrollbar styling */}
                  <div className="grid grid-cols-3 md:grid-cols-5 gap-4 max-h-[60vh] overflow-y-auto custom-scroll pb-4 pr-2">
                    {filteredChampions.map((champion) => (
                      <motion.div
                        key={champion}
                        className="flex flex-col items-center gap-2 p-3 bg-dark/70 rounded-lg border border-primary/20"
                      >
                        <img
                          src={`https://ddragon.leagueoflegends.com/cdn/15.2.1/img/champion/${formatChampionName(
                            champion
                          )}.png`}
                          className="w-16 h-16 rounded-full"
                          alt={champion}
                        />
                        <span className="text-sm">{champion}</span>
                      </motion.div>
                    ))}
                  </div>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Action Buttons */}
          <div className="flex justify-between mt-8">
            <button
              onClick={() => setShowChampionsModal(true)}
              className="px-6 py-3 bg-primary/20 hover:bg-primary/30 text-primary rounded-lg flex items-center gap-2"
            >
              <img
                src="/images/champion.png"
                className="h-6 w-6"
                alt="Champions"
              />
              Show Champions
            </button>

            <button
              onClick={handleUseAccount}
              className="px-6 py-3 bg-green-500/20 hover:bg-green-500/30 text-green-500 rounded-lg flex items-center gap-2"
            >
              <FaUserShield />
              Use Account
            </button>
          </div>

          {/* Credentials Modal */}
          <AnimatePresence>
            {showCredentialsModal && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center"
              >
                <motion.div
                  initial={{ y: 50 }}
                  animate={{ y: 0 }}
                  className="bg-dark-800 border-2 border-primary/30 rounded-xl w-full max-w-md p-6 backdrop-blur-md"
                >
                  <div className="flex justify-between items-center mb-6 border-b border-primary/20 pb-4">
                    <h2 className="text-2xl font-bold text-primary">
                      Account Credentials
                    </h2>
                    <button
                      onClick={() => setShowCredentialsModal(false)}
                      className="text-light/60 hover:text-light transition-colors"
                    >
                      <FaTimes size={24} />
                    </button>
                  </div>

                  <div className="space-y-6">
                    <div className="space-y-2">
                      <label className="text-light/80 text-sm">Username</label>
                      <div className="flex items-center gap-2 bg-dark-800/50 p-3 rounded-lg">
                        <span className="text-light flex-1">
                          {credentials?.accusr}
                        </span>
                        <button
                          onClick={() =>
                            copyToClipboard(credentials?.accusr, "username")
                          }
                          className="p-2 hover:bg-primary/10 rounded-lg transition-colors"
                        >
                          {copiedField === "username" ? (
                            <FaCheck className="text-green-500" />
                          ) : (
                            <FaCopy className="text-light/60" />
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="space-y-2">
                      <label className="text-light/80 text-sm">Password</label>
                      <div className="flex items-center gap-2 bg-dark-800/50 p-3 rounded-lg">
                        <span className="text-light flex-1">
                          {credentials?.accpwd}
                        </span>
                        <button
                          onClick={() =>
                            copyToClipboard(credentials?.accpwd, "password")
                          }
                          className="p-2 hover:bg-primary/10 rounded-lg transition-colors"
                        >
                          {copiedField === "password" ? (
                            <FaCheck className="text-green-500" />
                          ) : (
                            <FaCopy className="text-light/60" />
                          )}
                        </button>
                      </div>
                    </div>

                    <button
                      onClick={() => setShowConfirmation("stop")}
                      className="w-full px-6 py-3 bg-red-500/20 hover:bg-red-500/30 text-red-400 rounded-lg flex items-center justify-center gap-2 transition-all"
                    >
                      <FaLock />
                      Stop Using Account
                    </button>
                  </div>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Confirmation Modal */}
          <AnimatePresence>
            {showConfirmation && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center"
              >
                <motion.div
                  initial={{ y: 50 }}
                  animate={{ y: 0 }}
                  className="bg-dark-800 border-2 border-primary/30 rounded-xl w-full max-w-md p-6 backdrop-blur-md"
                >
                  <h3 className="text-xl font-bold mb-4">
                    {showConfirmation === "use"
                      ? "Use Account"
                      : "Stop Using Account"}
                  </h3>
                  <p className="text-light/80 mb-6">
                    {showConfirmation === "use"
                      ? "Are you sure you want to use this account?"
                      : "Are you sure you want to stop using this account?"}
                  </p>
                  <div className="flex justify-end gap-4">
                    <button
                      onClick={() => setShowConfirmation(null)}
                      className="px-4 py-2 text-light/80 hover:text-light"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        if (showConfirmation === "use") {
                          confirmUseAccount();
                        } else {
                          handleStopUsingAccount();
                        }
                        setShowConfirmation(null);
                      }}
                      className="px-4 py-2 bg-primary/20 hover:bg-primary/30 text-primary rounded-lg"
                    >
                      Confirm
                    </button>
                  </div>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    </div>
  );
};

export default LeagueAccount;