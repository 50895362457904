import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  FaUsers,
  FaBook,
  FaQuestionCircle,
  FaUser,
  FaCog,
  FaSignOutAlt,
  FaHandHolding,
} from "react-icons/fa";
import { motion } from "framer-motion";

const Navbar = ({ user }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    try {
      const response = await fetch("https://api.eloheaven.net/logout", { withCredentials: true });

      if (response.ok) {
        window.location.href = "/";
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const getRoleName = (accountType) => {
    switch (accountType) {
      case 1:
        return "Owner";
      case 2:
        return "Admin";
      case 3:
        return "Moderator";
      case 4:
        return "Customer";
      case 5:
        return "Booster";
      default:
        return "Unknown";
    }
  };

  return (
    <motion.nav
      /*initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}*/
      className="bg-dark/90 backdrop-blur-md border-b border-light/10 p-3 fixed w-full z-50"
    >
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        <Link to="/dashboard" className="flex items-center space-x-3">
          <img
            src="/images/logo_white.png"
            alt="EloHeaven Logo"
            className="h-12"
          />
          <span className="text-light text-2xl font-semibold">EloHeaven</span>
        </Link>

        <div className="flex items-center space-x-8">
        {[
          { 
            to: [1, 2, 3, 5].includes(user?.accountType) ? "/league-accounts" : "/error", 
            icon: FaUsers, 
            text: "League Accounts" 
          },
          { 
            to: "/community-accounts",
            icon: FaUsers,
            text: "Community Accounts" 
          },
          { to: "/users", icon: FaUsers, text: "Users" },
          { to: "/rules", icon: FaBook, text: "Rules" },
          { to: "/help", icon: FaQuestionCircle, text: "Help" },
        ].map(({ to, icon: Icon, text }) => (
          <Link
            key={to}
            to={to}
            className="text-light text-lg font-medium flex items-center space-x-2 px-4 py-2 hover:text-primary transition-all"
          >
            <Icon className="text-1xl" />
            <span>{text}</span>
          </Link>
        ))}
        </div>

        <div className="relative" ref={dropdownRef}>
          <button onClick={toggleDropdown} className="focus:outline-none">
            <img
              src={user.ProfilePicture || "/images/default-avatar.png"}
              alt="Profile"
              className="h-12 w-12 rounded-full border-2 border-light/20 hover:border-primary transition-all"
            />
          </button>

          {isDropdownOpen && (
            <div className="absolute right-0 mt-3 w-72 bg-dark/90 backdrop-blur-md border border-light/10 rounded-lg shadow-lg">
              <div className="p-4 border-b border-light/10 flex items-center space-x-4">
                <img
                  src={user.ProfilePicture || "/images/default-avatar.png"}
                  alt="Profile"
                  className="h-14 w-14 rounded-full"
                />
                <div>
                  <p className="text-light font-semibold text-lg">
                    {user.username}
                  </p>
                  <p className="text-light/80 text-sm">
                    {getRoleName(user.accountType)}
                  </p>
                </div>
              </div>

              {[
                { to: "/profile", icon: FaUser, text: "My Profile" },
                { to: "/settings", icon: FaCog, text: "Settings" },
                { 
                  to: "/lend-account", 
                  icon: FaHandHolding, 
                  text: "Lend Account" 
                },
                {
                  to: "https://discord.gg/eloheaven",
                  icon: FaUsers,
                  text: "Discord",
                },
              ].map(({ to, icon: Icon, text }) => (
                <Link
                  key={to}
                  to={to}
                  className="block px-6 py-3 text-lg text-light hover:bg-light/10 flex items-center space-x-3"
                >
                  <Icon className="text-xl" />
                  <span>{text}</span>
                </Link>
              ))}

              <button
                onClick={handleLogout}
                className="w-full text-left px-6 py-3 text-lg text-red-500 hover:bg-red-500/10 flex items-center space-x-3"
              >
                <FaSignOutAlt className="text-xl" />
                <span>Logout</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </motion.nav>
  );
};

export default Navbar;